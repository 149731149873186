import ContentAgreement from "./ContentAgreement";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { date, month, year, formatBirthdate } from "../utils/helper";
import { toast } from "react-hot-toast";
import { setMemberInfo } from "store/member/memberSlice";
import { VIP_REGISTER_NAME } from "services/users";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useHandleLogout from "hooks/useHandleLogout";
import PopupCancelMembership from "./PopupCancelMembership";
import moment from "moment";

const MemberAgreement = (props) => {
  const { maxContent, isCoupon } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const memberInfo = useSelector((state) => state.member.memberInfo);
  const vipInfo = useSelector((state) => state.vip.vipInfo);
  const [disabled, setDisabled] = useState(false);
  const handleLogout = useHandleLogout();
  const dispatch = useDispatch();
  const [disabledButton, setDisabledButton] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const now = moment();
  const userInfo = location?.pathname === "/user-info";
  const [params, setParams] = useState({
    agree: "",
    newsletter: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    day: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    if (memberInfo?.first_name) {
      setDisabled(true);
      // when memberInfo state is already set then state setParams will get values from redux (memberInfo)
      setParams(
        Object.assign({}, memberInfo, {
          agree: true,
        })
      );
    } else {
      setDisabled(false);
      // Resetting params state if memberInfo is not set
      let userinfo =  localStorage.getItem('userInfo')
      if(userinfo){
        userinfo = JSON.parse(userinfo) ?? null;
      }
      const getBirthdate = formatBirthdate(userinfo?.birthdate).split("-");
      setParams({
        agree: "",
        newsletter: "",
        first_name: userinfo?.first_name,
        middle_name: userinfo?.middle_name,
        last_name: userinfo?.last_name,
        day: getBirthdate[0]|| "",
        month: getBirthdate[1] || "",
        year: getBirthdate[2] || ""
      });
    }
  }, [memberInfo]);

  const handleChange = (type, value) => {
    // Function to handle input changes based on input param
    setParams((prevParams) => ({
      ...prevParams,
      [type]: value,
    }));
  };

  const handleRegister = () => {
    // Checking when state params are empty , excluding newsletter which is not required
    // params.first_name = null
    const isEmpty = Object.entries(params)
      .filter(([key]) => key !== "newsletter" && key !== "middle_name")
      .some(([key, value]) => value === "" || value === null || value === false);

    if (isEmpty) {
      toast.error(t("missing_fields"));
      console.error("Required registration user fields are missing");
      return;
    }

    if(/\s/.test(params.first_name)){
      toast.error(t("first_name_space"));
      console.error("First name Spaces are not allowed in this field");
      return;
    }
    if(/\s/.test(params.middle_name)){
      toast.error(t("middle_name_space"));
      console.error("Spaces are not allowed in Middle name field");
      return;
    }
    if(/\s/.test(params.last_name)){
      toast.error(t("last_name_space"));
      console.error("Last name Spaces are not allowed in this field");
      return;
    }

    if((params.first_name && params.first_name.length > 50) || (params.last_name && params.last_name.length > 50)){
      toast.error(t("name_len"));
      return;
    }

    if(params.middle_name && params.middle_name.length > 50){
      toast.error(t("name_len"));
      return;
    }
    // Adding 0 in month & day when less than 10
    params.month = params?.month < 10 ? "0" + params?.month : params?.month;
    params.day = params?.day < 10 ? "0" + params?.day : params?.day;
    const body = {
      first_name: params.first_name,
      middle_name: params.middle_name,
      last_name: params.last_name,
      birthdate: [params?.year, params?.month, params?.day].join("-"), // join year, month and day
    };

    if (
      memberInfo.first_name &&
      (now.isAfter(moment(memberInfo?.expire_date)) ||
        memberInfo?.expire_date === null ||
        !memberInfo?.expire_date) &&
      !vipInfo.isVip
    ) {
      // Navigate to stamp page if member has expired and is not a vip member
      navigate("/stamp");
    } else if (memberInfo?.first_name) {
      // Navigate to coupon-detail if member has unexpired coupon
      navigate("/coupon-detail");
    } else {
      // Register VIP member
      VIP_REGISTER_NAME(body)
        .then((res) => {
          console.log(res)
          const data = res.data;
          const getBirthdate = formatBirthdate(body?.birthdate).split("-");
          dispatch(
            setMemberInfo({
              first_name: data?.first_name,
              middle_name: data?.middle_name,
              last_name: data?.last_name,
              birthdate: data?.birthdate,
              coupon_no: data?.coupon_no,
              email: data?.email,
              expire_date: data?.expire_date,
              day: getBirthdate[0] || "",
              month: getBirthdate[1] || "",
              year: getBirthdate[2] || "",
              newsletter: params.newsletter,
            })
          );
          navigate("/stamp");
        })
        .catch((error) => {
          toast.error(error.data || "Some error occured");
          if (typeof error === "object") {
            console.error("Coupon registration name & dob", JSON.stringify(error));
          } else {
            console.error("Coupon registration name & dob", error);
          }
        });
    }
  };

  const CustomSelect = (props) => {
    // Dynamic select component for day, month & year
    const { items, type } = props;
    return (
      <FormControl sx={{ mt: 0, width: "100%" }}>
        <Select
          disabled={disabled}
          value={params[type]}
          onChange={(e) => handleChange(type, e?.target?.value)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            height: 36,
            backgroundColor: disabled ? "#dbdada" : "#FFFFFF",
            fontFamily: "Hiragino",
            fontSize: 14,
            border: 0,
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 100,
                border: 0,
              },
            },
          }}
        >
          <MenuItem disabled value="">
            <div className="text-sm font-light text-zinc-400 capitalize">
              {t(type)}
            </div>
          </MenuItem>
          {items().map((item) => (
            <MenuItem
              style={{
                fontFamily: "Hiragino",
                fontSize: "12px",
                fontWeight: "400",
              }}
              key={item}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <div className="px-2">
      <div
        className={`flex flex-col self-stretch px-4 p-4 mt-4 bg-white rounded-lg border border-solid border-zinc-300 text-neutral-700 ${
          maxContent ? "" : "max-h-card overflow-x-scroll"
        }`}
      >
        <div className="justify-center px-4 py-2 text-sm leading-5 text-white bg-neutral-700">
          {isCoupon ? t("overseas_coupon") : t("overseas_customer")}
        </div>
        <div className="mt-4 leading-5">
          <ContentAgreement />
        </div>
      </div>
      {maxContent && (
        <div
          onClick={() => navigate("/membership")}
          className="justify-center cursor-pointer hover:scale-105 items-center self-center px-16 py-3 m-auto mt-4 w-full text-sm text-center whitespace-nowrap rounded-sm border border-solid border-neutral-700"
        >
          Return to previous page
        </div>
      )}
      {!maxContent && (
        <>
          <div className="flex gap-2 pr-5 mt-4 justify-center items-center">
            <input
              id="myCheckbox"
              type="checkbox"
              checked={params?.agree}
              disabled={disabled}
              className="w-5 h-5 bg-white rounded-sm border border-solid border-zinc-300"
              onChange={(e) => handleChange("agree", e?.target?.checked)}
            />
            <label
              className="text-sm font-light text-neutral-700"
              htmlFor="myCheckbox"
            >
              {t("member_agreement")}
            </label>
          </div>
          <div className="px-4 mt-4 text-xs font-light leading-4 text-neutral-400">
            {t("member_agreement_note")}
          </div>
          {/* <div className="px-4 mt-4 w-full">
            <div className="text-sm font-light leading-6 text-neutral-700 w-full text-left">
              {t("name")}
            </div>
            <input
              disabled={disabled}
              className={`${
                disabled
                  ? "!bg-[#dbdada] text-zinc-400 border-[#a2a2a2]"
                  : "border-[#c4c4c4]"
              } justify-center items-start text-left py-2 w-full text-sm font-light leading-5 whitespace-nowrap bg-white border border-solid rounded-[4px] placeholder:text-zinc-400`}
              placeholder="Yamada Hanako"
              defaultValue={params?.name}
              onBlur={(e) => handleChange("name", e?.target?.value)}
            />
          </div> */}
          <div className="px-4 mt-4 w-full">
            <div className="text-sm font-light leading-6 text-neutral-700 w-full text-left">
              {t("first_name")}
            </div>
            <input
              disabled={disabled}
              className={`${
                disabled
                  ? "!bg-[#dbdada] text-zinc-400 border-[#a2a2a2]"
                  : "border-[#c4c4c4]"
              } justify-center items-start text-left py-2 w-full text-sm font-light leading-5 whitespace-nowrap bg-white border border-solid rounded-[4px] placeholder:text-zinc-400`}
              placeholder={t("first_name")}
              defaultValue={params?.first_name}
              onBlur={(e) => handleChange("first_name", e?.target?.value)}
            />
          </div>
          <div className="px-4 mt-4 w-full">
            <div className="text-sm font-light leading-6 text-neutral-700 w-full text-left">
              {t("middle_name")}
            </div>
            <input
              disabled={disabled}
              className={`${
                disabled
                  ? "!bg-[#dbdada] text-zinc-400 border-[#a2a2a2]"
                  : "border-[#c4c4c4]"
              } justify-center items-start text-left py-2 w-full text-sm font-light leading-5 whitespace-nowrap bg-white border border-solid rounded-[4px] placeholder:text-zinc-400`}
              placeholder={t("middle_name")}
              defaultValue={params?.middle_name}
              onBlur={(e) => handleChange("middle_name", e?.target?.value)}
            />
          </div>
          <div className="px-4 mt-4 w-full">
            <div className="text-sm font-light leading-6 text-neutral-700 w-full text-left">
              {t("last_name")}
            </div>
            <input
              disabled={disabled}
              className={`${
                disabled
                  ? "!bg-[#dbdada] text-zinc-400 border-[#a2a2a2]"
                  : "border-[#c4c4c4]"
              } justify-center items-start text-left py-2 w-full text-sm font-light leading-5 whitespace-nowrap bg-white border border-solid rounded-[4px] placeholder:text-zinc-400`}
              placeholder={t("last_name")}
              defaultValue={params?.last_name}
              onBlur={(e) => handleChange("last_name", e?.target?.value)}
            />
          </div>
          <div className="px-4 mt-2 w-full">
            <div className="text-sm font-light leading-6 text-neutral-700 w-full ext-left">
              {t("date_birth")}
            </div>
            <div className="flex gap-2 justify-between">
              <CustomSelect items={date} type="day" />
              <CustomSelect items={month} type="month" />
              <CustomSelect items={year} type="year" />
            </div>
          </div>
          <div className="flex px-4 mt-4 gap-2 w-full text-sm font-light text-neutral-700">
            <input
              id="newsletter"
              type="checkbox"
              checked={params?.newsletter}
              disabled={disabled}
              className="w-5 h-5 bg-white rounded-sm border border-solid border-zinc-300"
              onChange={(e) => handleChange("newsletter", e?.target?.checked)}
            />
            <label className="grow cursor-pointer" htmlFor="newsletter">
              {t("member_newsletter")}
            </label>
          </div>
          <div className="px-4 w-full">
            <div
              className={`frame ${
                disabledButton && "opacity-80 cursor-not-allowed"
              }`}
              style={{
                background: disabledButton && "#a4a5a6",
                boxShadow: disabledButton && "none",
                border: disabledButton && "solid 1px #a2a2a2",
              }}
              onClick={() => !disabledButton && handleRegister()}
            >
              <div className="text-wrapper font-hankyu font-bold">
                {isCoupon
                  ? t("get_coupon")
                  : userInfo
                  ? "back"
                  : t("new_member")}
              </div>
            </div>
          </div>
          <div className="px-4 py-3 w-full">
            {userInfo ? (
              <p
                onClick={() => setOpenPopup(true)}
                className="underline text-[#7f4d3a] font-semibold text-center hover:scale-105 cursor-pointer text-xs"
              >
                Delete Account
              </p>
            ) : (
              <p
                onClick={handleLogout}
                className="underline text-[#7f4d3a] font-semibold text-center hover:scale-105 cursor-pointer text-xs"
              >
                Back to Login
              </p>
            )}
          </div>
        </>
      )}

      <PopupCancelMembership
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
      />
    </div>
  );
};

export default MemberAgreement;
